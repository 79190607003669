 <template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <div v-if="navigation == 'dashboard'" class="dashbord-nav">
        <feather-icon icon="FileTextIcon" size="18"></feather-icon>
        <span>Work Dashboard</span>
      </div>
      <h4 v-else-if="navigation == 'viewedit'">View Edit</h4>
      <h4 v-else-if="navigation == 'approval'">Timesheet - Approvals</h4>
      <h4 v-else-if="navigation == 'employeeapproval'">
        Timesheet - Approvals
      </h4>
      <h4 v-else-if="navigation == 'timesheet'">TimeSheet</h4>
      <h4 v-else-if="navigation == 'allproject'">All Project</h4>
      <h4 v-else-if="navigation == 'dashboard-analytics'">
        Dashboard-Analytics
      </h4>
      <h4 v-else-if="navigation == 'clockinout'">Clock InOut</h4>
      <h4 v-else-if="navigation == 'teammembers'">Team Members</h4>
      <h4 v-else-if="navigation == 'reports'">Report</h4>
      <h4 v-else-if="navigation == 'generalreports'">General Report</h4>
      <h4 v-else-if="navigation == 'clients'">Master Setting</h4>
      <h4 v-else-if="navigation == 'grouptable'">Master Setting</h4>
      <h4 v-else-if="navigation == 'projecttable'">Master Setting</h4>
      <h4 v-else-if="navigation == 'projectusertable'">Master Setting</h4>
      <h4 v-else-if="navigation == 'projecttype'">Master Setting</h4>

      <h4 v-else-if="navigation == 'projectstatus'">Master Setting</h4>
      <h4 v-else-if="navigation == 'role'">Master Setting</h4>
      <h4 v-else-if="navigation == 'tag'">Master Setting</h4>
      <h4 v-else-if="navigation == 'usergroup'">Master Setting</h4>
      <h4 v-else-if="navigation == 'user'">Master Setting</h4>
    </div> -->
    <!-- <b-navbar-nav class="nav align-items-center ml-auto"> -->
    <!-- <search-bar></search-bar> -->
    <!-- <span class="stop-time">
        <b-icon icon="Square-fill" class="stop-acticity"></b-icon>
        

      </span>
       <span class="stop-time">
       <b-icon icon="Play" class="stop-acticity" ></b-icon>
        

      </span> -->
    <!-- <InOut></InOut>

      <span class="mx-1"
        ><feather-icon
          icon="BellIcon"
          size="21"
          badge="4"
          badge-classes="badge-danger"
        ></feather-icon
      ></span>
      <user-profile></user-profile> -->
    <!-- </b-navbar-nav> -->
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import SearchBar from "../../@core/layouts/components/app-navbar/components/SearchBar.vue";
import UserProfile from "./UserProfile.vue";
import InOut from "./clockInOut.vue";
import { eventBus } from "@/main";
// import InOut from "./InOut.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    SearchBar,
    UserProfile,
    InOut,
    // Navbar Components
    DarkToggler,
    InOut,
  },
  data() {
    return {
      selected: { title: "Rahul(User)" },
      users: [],
      userId: null,
    };
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    navigation: {
      get() {
        return this.$route.name;
      },
    },
  },

  watch: {
    userId() {
      this.changeDataOfUser();
    },
  },

  mounted() {
    this.userId = this.userInfo.id;
    // this.userList();
  },

  methods: {
    /*user List*/
    async userList() {
      let input = {};
      let response = await this.getHTTPPostResponse(
        "admin/master/user",
        input,
        false
      );
      if (response && response.data) {
        let data = response.data;

        this.users = data;
        // console.log(this.users);
      }
    },

    changeDataOfUser() {
      eventBus.$emit("changeUser", this.userId);
    },
  },
};
</script>
<style>
.main-menu .main-menu-content {
  height: calc(100% - 4.45rem - 224px) !important;
  /* scroll-behavior: smooth; */
}
</style>