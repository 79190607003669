<template>
  <!--NOTINUSE-->
  <div>
    <span>{{ time | secondsInMinutes }}</span>
    <span class="stop-time" v-if="play === true" @click="inOut()">
      <b-icon
        icon="Square-fill"
        class="stop-acticity"
        v-if="play === true"
      ></b-icon>
    </span>

    <span class="stop-time" v-if="play === false" @click="inOut()">
      <b-icon icon="Play" class="stop-acticity"></b-icon>
    </span>
  </div>
  <!-- </div> -->
</template>

<script>
import * as moment from "moment/moment";
import { eventBus } from "@/main";
export default {
  data() {
    return {
      play: false,
      timerStart: false,
      task_log_id: null,
      time: 0,
      timer: null,
      // timerStart: false,
      clock_id: null,
      status: null,
      startDateTime: null,
    };
  },
  mounted() {
    this.clockInOut();
  },
  methods: {
    /*On-click In/Out button*/
    inOut() {
      if (this.loader) {
        return false;
      }

      if (this.play === false) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
      this.play = !this.play;
    },

    /*Timer Start*/
    startTimer() {
      this.timerStart = true;
      this.time = 0;
      this.startDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
      this.saveData();
    },

    /*Timer Stop*/
    stopTimer() {
      this.timerStart = false;
      this.time = 0;
      clearInterval(this.timer);
      this.endDateTime = moment(new Date())
        .add(this.time, "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      this.saveData();
    },

    /*user List*/
    async clockInOut() {
      let input =
        typeof this.user_id === "object"
          ? {
              date: moment(new Date()).format("YYYY-MM-DD"),
              user_id: this.userInfo.id,
            }
          : {
              date: moment(new Date()).format("YYYY-MM-DD"),
              user_id: this.user_id,
            };

      let response = await this.getHTTPPostResponse(
        "clock-in-out/list",
        input,
        false
      );

      if (response && response.data) {
        let data = response.data;
        let findFirstInTime = [];

        /*  for loop[ data is object] for get only in time log fetch*/
        for (let i = 0; i < Object.keys(data).length; i++) {
          if (data[i].out_date === null) {
            findFirstInTime.push(data[i]);
          } else {
            break;
          }
        }

        if (findFirstInTime && findFirstInTime.length) {
          let indexOfFirstIn = findFirstInTime.length - 1;

          if (
            findFirstInTime[indexOfFirstIn] &&
            findFirstInTime[indexOfFirstIn].out_date === null
          ) {
            this.startDateTime = moment(
              `${findFirstInTime[indexOfFirstIn].in_date} ${findFirstInTime[indexOfFirstIn].in_time}`
            ).format("YYYY-MM-DD HH:mm:ss");

            //time count
            var now = moment(new Date()); //todays date
            var duration = moment.duration(now.diff(this.startDateTime));
            this.time = duration.asSeconds().toFixed(0);
            this.timer = setInterval(() => {
              this.time++;
            }, 1000);

            /*  display icon*/
            this.timerStart = true;
            this.play = true;
            this.clock_id = findFirstInTime[indexOfFirstIn].id;

            /* variable & event bus for pass active/deactive status pass*/

            eventBus.$emit("activeStatusAfterReload", "true");
          }
        } else {
          /*  display icon*/
          this.play = false;
          this.clock_id = null;
        }
      }
    },
    /* Save In/Out Data*/

    async saveData() {
      this.loader = true;
      let input = {
        clock_id: this.clock_id ? this.clock_id : null,
        type: "manual",
        in_time: !this.clock_id ? moment(new Date()).format("HH:mm:ss") : null,
        in_date: !this.clock_id
          ? moment(new Date()).format("YYYY-MM-DD")
          : null,
        out_time: this.clock_id ? moment(new Date()).format("HH:mm:ss") : null,
        out_date: this.clock_id
          ? moment(new Date()).format("YYYY-MM-DD")
          : null,
        status: this.clock_id ? "out" : "in",
      };

      let response = await this.getHTTPPostResponse(
        "/clock-in-out/clock",
        input,
        true
      );
      if (response && response.status == 200) {
        let data = response.data.data;
        this.clock_id = data && data.id ? data.id : null;

        /* variable & event bus for pass active/deactive status pass*/

        let active = data && data.in_time ? "true" : "false";
        eventBus.$emit("activeStatus", active);

        /* after api call update list */
        eventBus.$emit("loadInOutList", true);
        eventBus.$emit("actionLog", true);
        setTimeout(() => {
          this.loader = false;
        }, 1000);
      } else {
        this.loader = false;
      }
    },
  },
};
</script>
