var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"time-tracker"},[_c('validation-observer',{ref:"manualTaskRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"select-project",attrs:{"cols":"12","md":"6"}},[_c('b-overlay',{attrs:{"show":_vm.load_projects,"rounded":"sm"}},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-project-ticket",attrs:{"id":"project_id","name":"project_id","label":"name","options":_vm.projects,"reduce":function (project) { return project.id; },"placeholder":"Select Project *","disabled":_vm.task_log_id ? true : false},model:{value:(_vm.project_id),callback:function ($$v) {_vm.project_id=$$v},expression:"project_id"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"select-ticket",attrs:{"cols":"12","md":"6"}},[_c('b-overlay',{attrs:{"show":_vm.load_tickets,"rounded":"sm"}},[_c('validation-provider',{attrs:{"name":"Ticket","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-project-ticket",attrs:{"id":"ticket_id","hide-details":"","name":"ticket_id","label":"number","options":_vm.tickets,"reduce":function (ticket) { return ticket.id; },"placeholder":"Select Ticket","disabled":_vm.task_log_id ? true : false},model:{value:(_vm.ticket_id),callback:function ($$v) {_vm.ticket_id=$$v},expression:"ticket_id"}})]}}],null,true)})],1)],1)],1),_c('b-form-group',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":"Task","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"select-item text-area time-log-text-area",attrs:{"name":"task_name","type":"text","rows":_vm.$store.state.app.isFullScreenModal ?12 :3,"maxlength":"2000","placeholder":"Describe your task details in max 2000 character *","disabled":_vm.task_log_id ? true : false},model:{value:(_vm.task_name),callback:function ($$v) {_vm.task_name=$$v},expression:"task_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',{staticClass:"date-duration"},[_c('span',{staticClass:"date-duration-data"},[_vm._v(" When: ")]),_c('span',[_c('flat-pickr',{staticClass:"timer-toggle-form",attrs:{"config":_vm.configTaskDate,"name":"task_date"},model:{value:(_vm.task_date),callback:function ($$v) {_vm.task_date=$$v},expression:"task_date"}})],1)]),(!_vm.task_id)?_c('b-row',[_c('b-col',{staticClass:"select-project",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":"Start time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"select-time",style:(_vm.$store.state.app.isFullScreenModal ? 'text-align: start !important;':null),attrs:{"id":"myInput","name":"start_time","type":"time","placeholder":"03:29 PM"},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"select-ticket",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":"End time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:" select-time",style:(_vm.$store.state.app.isFullScreenModal ? 'text-align: start !important;':null),attrs:{"name":"end_time","type":"time","placeholder":"03:29 PM"},model:{value:(_vm.end_time),callback:function ($$v) {_vm.end_time=$$v},expression:"end_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"d-flex m-0"},_vm._l((_vm.tags),function(tag,tindex){return _c('div',{key:tindex,staticClass:"tag-timer-modal"},[_c('span',{staticClass:"tag-timer-modal-span"},[_vm._v(_vm._s(tag))]),_c('feather-icon',{staticClass:"x-icon-tag",attrs:{"icon":"XIcon","size":"10"},on:{"click":function($event){return _vm.tags.splice(tindex, 1)}}})],1)}),0)],1),_c('div',{staticClass:"timer-icons menual-time"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"save-btn-timer"},[_c('div',{staticClass:"icons"},[_c('b-button',{attrs:{"type":"submit","disabled":invalid || !_vm.checkTimeValid || _vm.btnloader},on:{"click":function($event){$event.preventDefault();return _vm.saveTasks()}}},[_vm._v(" Save ")])],1)]),_c('div',{staticClass:"clear-button-timer"},[_c('div',{staticClass:"icons"},[_c('b-button',{attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.clearForm()}}},[_vm._v(" Clear ")])],1)])]),_c('div',{staticClass:"feather-icon"},[_c('div',{staticClass:"icons"},[_c('custom-dropdown',{staticClass:"custom-drop",attrs:{"options":_vm.tagOptions,"icon":"TagIcon","multiple":""},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }