<template>
  <!-- Custom Notification -->
  <div class="notificationmodalInfo">
    <div
      class="overlay"
      @click.prevent="closeModal()"
    />
    <div class="d-flex justify-content-between mb-1">
      <h2
        style="font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #2178fb;"
      >Notifications </h2>
      <button
        v-if="allNotificationData && allNotificationData.count > 0"
        @click="readNotification('all',notificationIds)"
      >
        Read All
      </button>
    </div>
    <div class="notificationDetails">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="singleNote w-100"
      >

        <div class="d-flex justify-content-between w-100">
          <h4>{{ item.data.title }}</h4>
          <feather-icon
            icon="EyeIcon"
            @click="readNotification('single',item.id)"
          />
        </div>
        <p>{{ item.data.message }}</p>
        <p>{{ item.created_at | DateFormatFormnowOrLocal }}</p>

      </div>
      <div
        v-if="allNotificationData && allNotificationData.count == 0&& !notification_loader"
        class="d-flex align-items-center singleNote"
      >
        <div>
          <div class="d-flex justify-content-between" />
          <p>No Notifications Found</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: 'NotificationModal',
  props: ['allNotificationData'],
  data() {
    return {
      loader: false,
      notification_loader: false,
      items: [],
      notificationIds: [],
      totalCount: 0,
      totalNoticationCount: 0,
    }
  },
  watch: {
    allNotificationData(val) {
      this.items = val.notifications
    },
  },
  mounted() {
    this.getNotificationList()
  },
  methods: {
    closeModal() {
      eventBus.$emit('notificationModel', false)
    },
    async getNotificationList() {
      const data = this.allNotificationData.notifications
      this.items = data
      this.totalNoticationCount = this.allNotificationData.count
      const ids = []
      this.items.forEach(element => {
        ids.push(element.id)
      })
      this.notificationIds = ids
      this.notification_loader = false
    },
    async readNotification(type, id) {
      const input = {
        notification_id: type == 'all' ? this.notificationIds : [id],
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/notification-read',
        input,
        false,
      )
      if (response && response.data) {
        this.$emit('NotificationRead')
        this.getNotificationList()
      }
      eventBus.$emit('isNotification', true)
      this.$emit('NotificationCount')
      this.loading = false
    },
  },
}
</script>
