<template>
  <div>
    <b-dropdown
      dropright
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="userprofile-dropdown"
    >
      <template #button-content>
        <b-avatar
          size="40px"
          :text="$store.state.app.userInfo.name.substring(0, 2)"
          variant="info"
          badge
          :badge-variant="status == 'true' ? 'success' : 'secondary'"
          v-if="!userInfo.profile_pic"
        />

        <b-avatar
          size="40px"
          :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
          badge
          :badge-variant="status == 'true' ? 'success' : 'secondary'"
          v-if="userInfo.profile_pic"
        />
        <!-- <b-avatar
          size="40px"
          :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
        /> -->
      </template>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Log out</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { eventBus } from "@/main";
export default {
  name: "UserProfile",
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      status: false,
    };
  },

  mounted() {
    /* get active status  on click in out button*/
    eventBus.$on("activeStatus", (data) => {
      if (data) {
        this.status = data;
        // console.log(this.status);
      }
    });

    /* get active status  on load*/
    eventBus.$on("activeStatusAfterReload", (data) => {
      // console.log("this.status", data);
      if (data) {
        this.status = data;
      }
    });
  },
  methods: {},
};
</script>
<style lang="scss">
.userprofile-dropdown {
  .dropdown-item {
    color: #2178fb;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 15px;
    border-radius: 3px;
    line-height: 24px;
    &:active {
      background-color: #2178fb;
    }
    &:hover {
      background-color: rgba(33, 120, 251, 0.2);
      color: #2178fb;
    }
  }

  .b-avatar {
    background-color: #7367f0;
  }
  .b-avatar-text {
    span {
      font-size: calc(20px);
    }
  }
  ul {
    margin-bottom: 0;
  }
}
</style>